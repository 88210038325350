import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { fetchCarsByLaundry } from "../Api/Laundry";
import { useTranslation } from "react-i18next";

function EmployeesAndCars() {
  const { t } = useTranslation();
  const laundryId = localStorage.getItem("laundryId");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetchCarsByLaundry(laundryId);
      console.log(response.data);
      setData(response.data);
    } catch (err) {
      console.error("get orders for today err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="border border-[#9ea5ad] shadow-lg rounded-[10px] overflow-hidden">
      <table
        className={`bg-white text-MainText w-full table-fixed max-w-[screen] `}
      >
        <thead>
          <tr>
            <th className="text-[13px] border border-[#9ea5ad] p-[12px] max-md:w-[70px]">
              {t("Car Id")}
            </th>
            <th className="text-[13px] border border-[#9ea5ad] p-[12px] ">
              {t("Driver Name")}
            </th>
            <th className="text-[13px] border border-[#9ea5ad] p-[12px] max-md:w-[70px]">
              {t("Details")}
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            Array.from({ length: 5 }).map((_, outIndex) => (
              <tr key={outIndex}>
                {Array.from({ length: 3 }).map((_, inIndex) => (
                  <td key={inIndex}>
                    <Skeleton width={"100%"} height={35} />
                  </td>
                ))}
              </tr>
            ))
          ) : data.length > 0 ? (
            data.map((item) => (
              <tr key={item.id} className="">
                <td className="text-[13px] border border-[#9ea5ad] text-center p-[12px] max-md:w-[70px]">
                  {item.id}
                </td>
                <td className="text-[13px] border border-[#9ea5ad] text-center p-[12px]">
                  {item.driver.name}
                </td>
                <td className="text-[13px] border border-[#9ea5ad] text-center p-[12px] max-md:w-[70px]">
                  <Link
                    className="underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                    to={`/dashboard/employeesAndCars/${item.id}/${item.lat}/${item.lng}/${item.driver.name}/${item.driver.email}/${item.driver.driver_id}/${item.driver_phone}`}
                  >
                    {t("More")}
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <div className="whitespace-nowrap p-[12px]">
              {t("No data available")}
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default EmployeesAndCars;

import React, { createContext, useContext, useState } from "react";

const PopupContext = createContext();

export const usePopup = () => useContext(PopupContext);

function PopupProvider({ children }) {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [makeDecisionOpen, setMakeDecisionOpen] = useState(false);
  const [dashboardSideBarOpen, setDashboardSideBarOpen] = useState(false);
  const [updatePriceOpen, setUpdatePriceOpen] = useState(false);
  const [updateDeliveryTimeOpen, setUpdateDeliveryTimeOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [type, setType] = useState("");
  const [action, setAction] = useState("");
  const contextValue = {
    sideBarOpen,
    setSideBarOpen,
    makeDecisionOpen,
    setMakeDecisionOpen,
    dashboardSideBarOpen,
    setDashboardSideBarOpen,
    updatePriceOpen,
    setUpdatePriceOpen,
    updateDeliveryTimeOpen,
    setUpdateDeliveryTimeOpen,
    languageOpen,
    setLanguageOpen,
    type,
    setType,
    action,
    setAction,
  };
  return (
    <PopupContext.Provider value={contextValue}>
      {children}
    </PopupContext.Provider>
  );
}

export default PopupProvider;

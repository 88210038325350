import React from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

function Pagination({ totalPages, currentPage, itemPerPage, handlePageClick }) {
  const { t } = useTranslation();
  return (
    totalPages !== 1 && (
      <ReactPaginate
        initialPage={currentPage - 1}
        previousLabel={t("Prev")}
        nextLabel={t("Next")}
        breakLabel={"..."}
        pageCount={totalPages}
        perPage={itemPerPage}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={handlePageClick}
        containerClassName={"flex flex-wrap items-center gap-1"}
        activeLinkClassName={
          "py-[7.5px] px-[12px] cursor-default border-transparent hover:border-transparent bg-gradient-to-b from-[#78bcd0] to-[#00238c] text-white border-white rounded-[10px]"
        }
        previousLinkClassName={
          "py-[7.5px] px-[12px] bg-white border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white rounded-[10px] duration-300"
        }
        nextLinkClassName={
          "py-[7.5px] px-[12px] bg-white border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white rounded-[10px] duration-300"
        }
        pageLinkClassName={
          "py-[7.5px] px-[12px] bg-white border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white rounded-[10px] duration-300"
        }
        renderOnZeroPageCount={null}
      />
    )
  );
}

export default Pagination;

import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { usePopup } from "../Context/PopupContext";
import { useDashboard } from "../Context/DashboardContext";
import Popup from "./Popup";

function SelectLanguage() {
  const lng = localStorage.getItem("dpLanguage") || "English";
  const { i18n, t } = useTranslation();
  const { setSelectedLanguage, setDir } = useDashboard();
  const { languageOpen, setLanguageOpen } = usePopup();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("dpLanguage", lng);
    setSelectedLanguage(lng);
    setDir(lng === "Arabic" ? "rtl" : "ltr");
    setLanguageOpen(false);
  };
  return (
    <Popup
      toggleSwitch={languageOpen}
      children={
        <div className={`flex flex-col gap-5 relative`}>
          <div
            onClick={() => setLanguageOpen(false)}
            className={`${
              lng === "Arabic" ? "left-0" : "right-0"
            } absolute top-0 cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300 p-2 rounded-full bg-[#e2e2e2] hover:bg-[#c8c8c8]`}
          >
            <AiOutlineClose />
          </div>
          <div className="font-[500]">{t("Choose Language")}</div>
          <div className="flex gap-2">
            <div
              onClick={() => {
                changeLanguage("English");
              }}
              className={`${
                lng === "English" &&
                "bg-gradient-to-b from-[#78bcd0] to-[#00238c] text-white"
              } py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[10px] border border-[#9ea5ad] hover:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 w-fit flex justify-center items-center min-w-[82.17px]`}
            >
              {t("English")}
            </div>
            <div
              onClick={() => {
                changeLanguage("Arabic");
              }}
              className={`${
                lng === "Arabic" &&
                "bg-gradient-to-b from-[#78bcd0] to-[#00238c] text-white"
              } py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[10px] border border-[#9ea5ad] hover:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 w-fit flex justify-center items-center min-w-[82.17px]`}
            >
              {t("Arabic")}
            </div>
          </div>
        </div>
      }
      overLayClassName="z-40"
      className={`text-MainText bg-white max-md:min-w-full md:min-w-[600px] top-[50%] max-md:left-0 md:left-[50%] -translate-y-[50%] md:-translate-x-[50%] fixed z-50 overflow-hidden rounded-[10px] p-4`}
      onClose={() => setLanguageOpen(false)}
    />
  );
}

export default SelectLanguage;

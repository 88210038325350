import axios from "axios";
import Cookies from "js-cookie";

export const BASE_URL = "https://laundry-backend.tecrek.com/public";

export const adminToken = Cookies.get("dpAdminToken");
export const superAdminToken = Cookies.get("dpSuperAdminToken");
export const isSuper = superAdminToken ? true : false
const AdminBearerToken = adminToken ? adminToken : superAdminToken;

export const apiAdmin = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${AdminBearerToken}`,
  },
});

export const fetchTotal = async () => {
  try {
    const response = await apiAdmin.get(`/api/getTotal`);
    return response.data;
  } catch (error) {
    console.log("fetch total err : ", error);
  }
};
import React from "react";
import { usePopup } from "../Context/PopupContext";
import { useDashboard } from "../Context/DashboardContext";
import { useTranslation } from "react-i18next";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

function ProductItem({ id, name, price }) {
  const { t } = useTranslation();
  const { setUpdatePriceOpen } = usePopup();
  const { setProductId, setProductPrice } = useDashboard();

  return (
    <div className="border border-[#9ea5ad] shadow-lg bg-white flex justify-between md:items-center max-md:flex-col max-md:gap-2 text-[13px] rounded-[10px] p-[20px]">
      <div className="font-[500]">{name}</div>
      <div className="flex max-md:justify-between items-center gap-4">
        <div className="font-[500] text-[18px]">{price} $</div>
        <div
          onClick={() => {
            setUpdatePriceOpen(true);
            setProductId(id);
            setProductPrice(price);
          }}
        >
          <Link to={`${id}`}>
            <FaRegEdit className="min-w-[20px] min-h-[20px] mx-auto cursor-pointer text-[18px] text-PrimaryColor hover:text-HoverPrimaryColor duration-300" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProductItem;

import React from "react";
import Skeleton from "react-loading-skeleton";

function ProductItemSkeleton() {
  return (
    <div className="h-[46px] max-md:h-[70px] w-full rounded-[10px]">
      <Skeleton height={`100%`} />
    </div>
  );
}

export default ProductItemSkeleton;

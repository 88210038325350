import React, { useState } from "react";
import { updateProductPriceInLaundry } from "../Api/Laundry";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDashboard } from "../Context/DashboardContext";
import { useTranslation } from "react-i18next";

function UpdateProductPrice() {
  const { t } = useTranslation();
  const { productId, productPrice } = useDashboard();
  const laundryId = localStorage.getItem("laundryId");
  const [number, setNumber] = useState(productPrice);
  const [loading, setLoading] = useState(false);
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("price", number);
    try {
      setLoading(true);
      const response = await updateProductPriceInLaundry(
        laundryId,
        productId,
        formData
      );
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/products";
      }
      setNumber("");
    } catch (err) {
      console.error("update price err : ", err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <form className="flex flex-col gap-5 relative" onSubmit={submitHandler}>
      <div className="font-[500]">{t("Update product price")}</div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="number"
          >
            {t("Product Price")} <span className="text-red-600">*</span> :
          </label>
          <div>
            <input
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              id="number"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
              placeholder={t("Product Price")}
            />
          </div>
        </div>
        <button
          type="submit"
          onClick={submitHandler}
          className={`${
            loading ? "cursor-default pointer-events-none" : "cursor-pointer"
          } min-w-[81.38px] min-h-[38.34px] py-[7.5px] shadow-lg px-[12px] text-[13px] rounded-[10px] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            t("Update")
          )}
        </button>
      </div>
    </form>
  );
}

export default UpdateProductPrice;

import React, { useEffect, useState } from "react";
import { fetchLaundryById, updateLaundry } from "../Api/Laundry";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
function UpdateLaundry() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    nameEn: "",
    nameAr: "",
    descriptionEn: "",
    descriptionAr: "",
    phoneNumber: "",
    city: "",
    address: "",
    point: "",
  });
  const changeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name_en", data.nameEn);
    formData.append("name_ar", data.nameAr);
    formData.append("description_en", data.descriptionEn);
    formData.append("description_ar", data.descriptionAr);
    formData.append("phone_number", data.phoneNumber);
    formData.append("city", data.city);
    formData.append("address_line_1", data.address);
    formData.append("point", data.point);
    try {
      setLoading(true);
      const response = await updateLaundry(id, formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/laundries";
      }
    } catch (err) {
      console.error("update laundry data err : ", err);
    } finally {
      setLoading(false);
    }
  };

  const getLaundryById = async () => {
    try {
      setDataLoading(true);
      const response = await fetchLaundryById(id);
      console.log(response);
      setData((data) => ({
        ...data,
        nameEn: response.data.name_en,
        nameAr: response.data.name_ar,
        descriptionEn: response.data.description_en,
        descriptionAr: response.data.description_ar,
        phoneNumber: response.data.phone_number,
        city: response.data.address?.city,
        address: response.data.address?.address_line_1,
        point: response.data.point,
      }));
    } catch (err) {
      console.error("get laundry by id err : ", err);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getLaundryById();
  }, []);
  return (
    <form className="flex flex-col gap-5 relative" onSubmit={submitHandler}>
      <div className="font-[500]">{t("Update laundry")}</div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="nameEn"
          >
            {t("Name In English")} <span className="text-red-600">*</span> :
          </label>
          {dataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <input
              value={data.nameEn}
              name="nameEn"
              onChange={changeHandler}
              id="nameEN"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
              placeholder={t("Name In English")}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="nameAr"
          >
            {t("Name In Arabic")} <span className="text-red-600">*</span> :
          </label>
          {dataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <input
              value={data.nameAr}
              name="nameAr"
              onChange={changeHandler}
              id="nameAr"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
              placeholder={t("Name In Arabic")}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="descriptionEn"
          >
            {t("Description In English")}{" "}
            <span className="text-red-600">*</span> :
          </label>
          {dataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <input
              value={data.descriptionEn}
              name="descriptionEn"
              onChange={changeHandler}
              id="descriptionEn"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
              placeholder={t("Description In English")}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="descriptionAr"
          >
            {t("Description In Arabic")} <span className="text-red-600">*</span>{" "}
            :
          </label>
          {dataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <input
              value={data.descriptionAr}
              name="descriptionAr"
              onChange={changeHandler}
              id="descriptionAr"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
              placeholder={t("Description In Arabic")}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="phoneNumber"
          >
            {t("Phone Number")} <span className="text-red-600">*</span> :
          </label>
          {dataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <input
              type="number"
              value={data.phoneNumber}
              name="phoneNumber"
              onChange={changeHandler}
              id="phoneNumber"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
              placeholder={t("Phone Number")}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="city"
          >
            {t("City")} <span className="text-red-600">*</span> :
          </label>
          {dataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <input
              value={data.city}
              name="city"
              onChange={changeHandler}
              id="city"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
              placeholder={t("City")}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="address"
          >
            {t("Address")} <span className="text-red-600">*</span> :
          </label>
          {dataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <input
              value={data.address}
              name="address"
              onChange={changeHandler}
              id="address"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
              placeholder={t("Address")}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="point"
          >
            {t("Point")} <span className="text-red-600">*</span> :
          </label>
          {dataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <input
              value={data.point}
              name="point"
              onChange={changeHandler}
              id="point"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
              placeholder={t("Point")}
            />
          )}
        </div>
        <button
          type="submit"
          onClick={submitHandler}
          className={`${
            loading ? "cursor-default pointer-events-none" : "cursor-pointer"
          } min-w-[81.38px] min-h-[38.34px] py-[7.5px] shadow-lg px-[12px] text-[13px] rounded-[10px] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            t("Update")
          )}
        </button>
      </div>
    </form>
  );
}

export default UpdateLaundry;

import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { addAdmin } from "../Api/Auth";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function AddNewAdmin() {
  const { t } = useTranslation();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const [loading, setLoading] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordConfirm, setHidePasswordConfirm] = useState(true);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });
  const changeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("password confirmation", data.passwordConfirm);
    try {
      setLoading(true);
      const response = await addAdmin(formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/admins";
      }
    } catch (err) {
      console.error("add admin err : ", err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <form className="flex flex-col gap-5 relative" onSubmit={submitHandler}>
      <div className="font-[500]">{t("Add New Admin")}</div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="name"
          >
            {t("Name")} <span className="text-red-600">*</span> :
          </label>
          <input
            value={data.name}
            name="name"
            onChange={changeHandler}
            id="name"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Name")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="email"
          >
            {t("Email")} <span className="text-red-600">*</span> :
          </label>
          <input
            value={data.email}
            name="email"
            onChange={changeHandler}
            id="email"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Email")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="password"
          >
            {t("Password")} <span className="text-red-600">*</span> :
          </label>
          <div className="w-full relative">
            <input
              type={hidePassword ? "password" : "text"}
              value={data.password}
              name="password"
              onChange={changeHandler}
              id="password"
              className={`${
                lng !== "Arabic" ? "pr-5 pl-[12px]" : "pl-5 pr-[12px]"
              } py-[7.5px] shadow-lg text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]`}
              placeholder={t("Password")}
            />
            <div
              onClick={() => setHidePassword(!hidePassword)}
              className={`${
                lng === "Arabic"
                  ? "left-0 rounded-l-[10px]"
                  : "right-0 rounded-r-[10px]"
              } absolute cursor-pointer top-[50%] -translate-y-[50%] text-[13px] text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 h-full p-2 flex justify-center items-center`}
            >
              {hidePassword ? <FaEye /> : <FaEyeSlash />}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="passordConfirm"
          >
            {t("Password Confirmation")} <span className="text-red-600">*</span>{" "}
            :
          </label>
          <div className="w-full relative">
            <input
              type={hidePasswordConfirm ? "password" : "text"}
              value={data.passwordConfirm}
              name="passordConfirm"
              onChange={changeHandler}
              id="passordConfirm"
              className={`${
                lng !== "Arabic" ? "pr-5 pl-[12px]" : "pl-5 pr-[12px]"
              } py-[7.5px] shadow-lg text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]`}
              placeholder={t("Password Confirmation")}
            />
            <div
              onClick={() => setHidePasswordConfirm(!hidePasswordConfirm)}
              className={`${
                lng === "Arabic"
                  ? "left-0 rounded-l-[10px]"
                  : "right-0 rounded-r-[10px]"
              } absolute cursor-pointer top-[50%] -translate-y-[50%] text-[13px] text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 h-full p-2 flex justify-center items-center`}
            >
              {hidePasswordConfirm ? <FaEye /> : <FaEyeSlash />}
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        onClick={submitHandler}
        className={`${
          loading ? "cursor-default pointer-events-none" : "cursor-pointer"
        } min-w-[81.38px] min-h-[38.34px] py-[7.5px] px-[12px] text-[13px] rounded-[10px] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
      >
        {loading ? (
          <AiOutlineLoading3Quarters className="animate-spin" />
        ) : (
          t("Add")
        )}
      </button>
    </form>
  );
}

export default AddNewAdmin;

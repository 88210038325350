import React from "react";

function Popup({
  toggleSwitch,
  onClose,
  className,
  children,
  overLayClassName,
}) {
  return (
    <>
      {toggleSwitch && (
        <>
          <div
            className={`${className} duration-300 transition-all fixed animate-flashing`}
          >
            {children}
          </div>
          <div
            onClick={onClose}
            className={`${overLayClassName} z-20 fixed top-0 left-0 bg-black/50 w-screen h-screen animate-flashing`}
          ></div>
        </>
      )}
    </>
  );
}

export default Popup;

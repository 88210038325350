import React from "react";
import Map from "../Components/Map";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function DriverById() {
  const { t } = useTranslation();
  const { driverName, driverEmail, driverId, driverPhone } = useParams();
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col border border-[#9ea5ad] shadow-lg bg-white rounded-[10px] p-[20px] text-[13px]">
        <div className="flex items-center gap-1 w-full whitespace-nowrap">
          <strong>{t("Driver id")} :</strong> {driverId}
        </div>
        <div className="flex items-center gap-1 w-full whitespace-nowrap">
          <strong>{t("Driver name")} :</strong> {driverName}
        </div>
        <div className="flex items-center gap-1 w-full whitespace-nowrap">
          <strong>{t("Driver email")} :</strong> {driverEmail}
        </div>
        <div className="flex items-center gap-1 w-full whitespace-nowrap">
          <strong>{t("Driver phone")} :</strong> {driverPhone}
        </div>
      </div>
      <div className="flex flex-col border border-[#9ea5ad] shadow-lg bg-white rounded-[10px]">
        <div className="flex items-center gap-1 w-full whitespace-nowrap p-[20px] text-[13px]">
          <strong>{t("Car location")} :</strong>
        </div>
        <Map />
      </div>
    </div>
  );
}

export default DriverById;

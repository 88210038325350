import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css'
import 'leaflet/dist/leaflet.css';
import "react-datepicker/dist/react-datepicker.css";
import Admin from './Pages/Admin';
import Dashboard from './Pages/Dashboard';
import MainDashPage from './Pages/MainDashPage';
import Prices from './Pages/Products';
import EmployeesAndCars from './Pages/EmployeesAndCars';
import Orders from './Pages/Orders';
import Reports from './Pages/Reports';
import Transactions from './Pages/Transactions';
import { SkeletonTheme } from 'react-loading-skeleton';
import DriverById from './Pages/DriverById';
import { useDashboard } from './Context/DashboardContext';
import { useEffect } from 'react';
import { adminToken, superAdminToken } from './Api/api';
import UpdateOrder from './Pages/UpdateOrder';
import UpdateProductPrice from './Pages/UpdateProductPrice';
import Laundries from './Pages/Laundries';
import AddLaundry from './Pages/AddLaundry';
import UpdateLaundry from './Pages/UpdateLaundry';
import Admins from './Pages/Admins';
import AddNewAdmin from './Components/AddNewAdmin';


function App() {
  const lng = localStorage.getItem("dpLanguage") || "English";
  const {dir,setDir} = useDashboard()
    useEffect(() => {
      document.body.dir = dir;
      setDir(lng === "Arabic" ? "rtl" : "ltr");
  }, [dir,lng]);
    const router = createBrowserRouter([
    {
      path: "/",
      element: <Admin/>,
    },
    {
      path: "/dashboard",
      element: adminToken || superAdminToken ? <Dashboard /> : <Navigate to="/" />, children: [
        {index : true , element : <MainDashPage/>},
        {path: "laundries", element: superAdminToken ? <Laundries /> : <Navigate to="/"/>},
        {path: "laundries/addNewLaundry", element: superAdminToken ? <AddLaundry /> : <Navigate to="/"/>},
        {path: "admins", element: superAdminToken ? <Admins /> : <Navigate to="/"/>},
        {path: "admins/addNewAdmin", element: superAdminToken ? <AddNewAdmin /> : <Navigate to="/"/>},
        {path: "laundries/:id", element: superAdminToken ? <UpdateLaundry /> : <Navigate to="/"/>},
        {path: "employeesAndCars", element: <EmployeesAndCars />},
        {path : "employeesAndCars/:carId/:lat/:lng/:driverName/:driverEmail/:driverId/:driverPhone", element: <DriverById/>},
        {path : "orders" , element : <Orders/>},
        {path : "orders/:id" , element : <UpdateOrder/>},
        {path : "transactions" , element : <Transactions/>},
        {path : "products" , element : <Prices/>},
        {path : "products/:id" , element : <UpdateProductPrice/>},
        {path : "reports" , element : <Reports/>},
      ]
    },
      ]);
  return (
    <div className='text-MainText font-MainFont'>
      <SkeletonTheme baseColor="#ebebeb" highlightColor="#f5f5f5">
      <RouterProvider router={router} />
      </SkeletonTheme>
    </div>
  );
}

export default App;

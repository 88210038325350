import React, { useEffect, useState } from "react";
import { fetchUsers, postLaundry } from "../Api/Laundry";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDashboard } from "../Context/DashboardContext";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

function AddLaundry() {
  const { t } = useTranslation();
  const { productId, productPrice } = useDashboard();
  const laundryId = localStorage.getItem("laundryId");
  const [number, setNumber] = useState(productPrice);
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [adminsLoading, seteAdminsLoading] = useState(true);
  const [data, setData] = useState({
    nameEn: "",
    nameAr: "",
    descriptionEn: "",
    descriptionAr: "",
    phoneNumber: "",
    city: "",
    address: "",
    point: "",
    adminId: "",
    lat: "",
    lng: "",
  });
  const changeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getAdmins = async () => {
    try {
      seteAdminsLoading(true);
      const response = await fetchUsers(1);
      console.log(response);
      setAdmins(response.data);
    } catch (err) {
      console.error("get admins data err : ", err);
    } finally {
      seteAdminsLoading(false);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("price", number);
    try {
      setLoading(true);
      const response = await postLaundry(laundryId, productId, formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/products";
      }
      setNumber("");
    } catch (err) {
      console.error("update price err : ", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAdmins();
  }, []);
  return (
    <form className="flex flex-col gap-5 relative" onSubmit={submitHandler}>
      <div className="font-[500]">{t("Add new laundry")}</div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="nameEn"
          >
            {t("Name In English")} <span className="text-red-600">*</span> :
          </label>
          <input
            value={data.nameEn}
            name="nameEn"
            onChange={changeHandler}
            id="nameEN"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Name In English")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="nameAr"
          >
            {t("Name In Arabic")} <span className="text-red-600">*</span> :
          </label>
          <input
            value={data.nameAr}
            name="nameAr"
            onChange={changeHandler}
            id="nameAr"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Name In Arabic")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="descriptionEn"
          >
            {t("Description In English")}{" "}
            <span className="text-red-600">*</span> :
          </label>
          <input
            value={data.descriptionEn}
            name="descriptionEn"
            onChange={changeHandler}
            id="descriptionEn"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Description In English")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="descriptionAr"
          >
            {t("Description In Arabic")} <span className="text-red-600">*</span>{" "}
            :
          </label>
          <input
            value={data.descriptionAr}
            name="descriptionAr"
            onChange={changeHandler}
            id="descriptionAr"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Description In Arabic")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="phoneNumber"
          >
            {t("Phone Number")} <span className="text-red-600">*</span> :
          </label>
          <input
            type="number"
            value={data.phoneNumber}
            name="phoneNumber"
            onChange={changeHandler}
            id="phoneNumber"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Phone Number")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="city"
          >
            {t("City")} <span className="text-red-600">*</span> :
          </label>
          <input
            value={data.city}
            name="city"
            onChange={changeHandler}
            id="city"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("City")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="address"
          >
            {t("Address")} <span className="text-red-600">*</span> :
          </label>
          <input
            value={data.address}
            name="address"
            onChange={changeHandler}
            id="address"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Address")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="point"
          >
            {t("Point")} <span className="text-red-600">*</span> :
          </label>
          <input
            value={data.point}
            name="point"
            onChange={changeHandler}
            id="point"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Point")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="admin"
          >
            {t("Select Admin")} <span className="text-red-600">*</span> :
          </label>
          {adminsLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <select
              value={data.adminId}
              name="adminId"
              onChange={changeHandler}
              id="admin"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            >
              <option disabled value="">
                Select admin
              </option>
              {admins.map((admin) => (
                <option
                  className="text-MainText bg-white"
                  key={admin.id}
                  value={admin.id}
                >
                  {admin.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="lat"
          >
            {t("Lat")} <span className="text-red-600">*</span> :
          </label>
          <input
            value={data.lat}
            name="lat"
            onChange={changeHandler}
            id="lat"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Lat")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="lng"
          >
            {t("Lng")} <span className="text-red-600">*</span> :
          </label>
          <input
            value={data.lng}
            name="lng"
            onChange={changeHandler}
            id="lng"
            className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Lng")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="img"
          >
            {t("Img")} <span className="text-red-600">*</span> :
          </label>
          <input
            type="file"
            id="img"
            className="bg-white py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
            placeholder={t("Img")}
          />
        </div>
        <button
          type="submit"
          onClick={submitHandler}
          className={`${
            loading ? "cursor-default pointer-events-none" : "cursor-pointer"
          } min-w-[81.38px] min-h-[38.34px] py-[7.5px] shadow-lg px-[12px] text-[13px] rounded-[10px] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            t("Add")
          )}
        </button>
      </div>
    </form>
  );
}

export default AddLaundry;

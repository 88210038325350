import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useParams } from "react-router-dom";

function Map() {
  const { lat, lng } = useParams();
  const containerStyle = {
    width: "100%",
    height: "100vh",
  };

  const center = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };
  console.log(center);

  return (
    <LoadScript googleMapsApiKey="AIzaSyCd18WhThXifGqoKEswWSU9EoyzfacTufg">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={20}>
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
}

export default Map;

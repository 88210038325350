import React, { useState } from "react";
import pic from "../Assets/Imgs/logo.png";
import { adminLogin } from "../Api/Auth";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Cookies from "js-cookie";
import { toastSuccess } from "../Components/ToastNotification/Toast";
import { useTranslation } from "react-i18next";

function Admin() {
  const { t } = useTranslation();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [hidePassword, setHidePassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const changeHandler = (e) => {
    setData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    try {
      setLoading(true);
      const response = await adminLogin(formData);
      console.log(response);
      if (response.success) {
        toastSuccess(response.message);
        if (response.data.user.user_type_id === 1) {
          Cookies.set("dpAdminToken", response.data.token);
        } else if (response.data.user.user_type_id === 4) {
          Cookies.set("dpSuperAdminToken", response.data.token);
        }
        window.location.pathname = "/dashboard";
      }
    } catch (error) {
      console.error("error in admin login : ", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="w-full h-screen min-h-screen adminBackground bg-cover max-md:bg-left-bottom">
      <form
        onSubmit={submitHandler}
        className={`${
          lng === "Arabic" ? "ml-auto" : "mr-auto"
        } p-10 max-md:p-5 md:max-w-[350px] md:min-h-full bg-white flex flex-col items-center max-md:gap-5 shadow-2xl max-md:w-full`}
      >
        <div className="md:basis-[1/4] max-md:max-w-[270px] max-md:max-h-[270px]">
          <img className="w-full h-full" src={pic} alt="" />
        </div>
        <div className="flex flex-col gap-5 md:my-auto w-full">
          <div className="flex flex-col gap-2">
            <div className="font-[500] text-PrimaryColor leading-[1.2]">
              {t("Welcome")}
            </div>
            <div className="text-[13px]">{t("Please login")}</div>
          </div>
          <div className="flex flex-col gap-3 w-full">
            <div className="w-full flex flex-col gap-2">
              <label htmlFor="email" className="text-[13px] font-[500]">
                {t("Email")} <span className="text-red-600">*</span>
              </label>
              <input
                className="w-full font-[400] leading-[1.5] text-[13px] px-[12px] py-[7.52px] outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[10px] cursor-pointer"
                type="text"
                name="email"
                id="email"
                value={data.email}
                onChange={changeHandler}
                placeholder={t("Your Email")}
              />
            </div>
            <div className="w-full flex flex-col gap-2 relative">
              <label htmlFor="password" className="text-[13px] font-[500]">
                {t("Password")} <span className="text-red-600">*</span>
              </label>
              <div className="w-full relative">
                <input
                  className={`${
                    lng !== "Arabic" ? "pr-5 pl-[12px]" : "pl-5 pr-[12px]"
                  } w-full font-[400] leading-[1.5] text-[13px] py-[7.52px] outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[10px] cursor-pointer`}
                  type={hidePassword ? "password" : "text"}
                  name="password"
                  id="password"
                  value={data.password}
                  onChange={changeHandler}
                  placeholder={t("Your Password")}
                />
                <div
                  onClick={() => setHidePassword(!hidePassword)}
                  className={`${
                    lng === "Arabic"
                      ? "left-0 rounded-l-[10px]"
                      : "right-0 rounded-r-[10px]"
                  } absolute cursor-pointer top-[50%] -translate-y-[50%] text-[13px] text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 h-full p-2 flex justify-center items-center`}
                >
                  {hidePassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className={`${
              loading && "cursor-default pointer-events-none"
            } min-h-[35.82px] text-[13px] px-[12px] py-[7.52px] font-[400] cursor-pointer rounded-[10px] bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 w-full flex justify-center items-center`}
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              t("Login")
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Admin;

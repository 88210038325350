import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { usePopup } from "../Context/PopupContext";
import { MdDashboard } from "react-icons/md";
import { FaPowerOff } from "react-icons/fa";
import { TbWashMachine } from "react-icons/tb";
import { AiOutlineClose } from "react-icons/ai";
import { FaDollarSign } from "react-icons/fa";
import { GrLanguage } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import logo from "../Assets/Imgs/logo.png";
import { isSuper } from "../Api/api";

function DashboardSidebar({ mob }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const {
    sideBarOpen,
    setSideBarOpen,
    setMakeDecisionOpen,
    setAction,
    setDashboardSideBarOpen,
    setLanguageOpen,
  } = usePopup();
  const location = useLocation();
  function isActive(path) {
    return location.pathname === path;
  }
  return (
    <div className="h-full min-h-screen z-50 bg-gradient-to-b from-[#78bcd0] to-[#00238c]">
      <div
        className={`${
          !mob ? (sideBarOpen ? "w-[240px]" : "w-[38px]") : "w-full"
        } relative h-full text-white duration-300`}
      >
        {mob && (
          <div
            onClick={() => setDashboardSideBarOpen(false)}
            className={`${
              lng === "Arabic" ? "left-0" : "right-0"
            } my-1 absolute top-0 cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300 p-2 rounded-full bg-[#e2e2e2] hover:bg-[#c8c8c8]`}
          >
            <AiOutlineClose />
          </div>
        )}
        <nav>
          <h1
            className={`${
              !mob ? (sideBarOpen ? "block" : "invisible") : null
            } min-w-[200px] w-full text-center text-white font-bold text-[18px] py-1 flex md:justify-center items-center gap-1`}
          >
            <div className="w-[50px] h-[50px]">
              <img className="w-full h-full" src={logo} alt="" />
            </div>
            {t("Dobe Pro")}
          </h1>
          <Link
            onClick={() => setDashboardSideBarOpen(false)}
            to="/dashboard"
            className={`flex items-center gap-2 w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]
                    ${isActive("/dashboard") ? "bg-HoverPrimaryColor" : ""}`}
            exact={true}
          >
            <MdDashboard />
            <h1
              className={`${!mob ? (sideBarOpen ? "block" : "hidden") : null}`}
            >
              {t("Dashboard")}
            </h1>
          </Link>
          {isSuper && (
            <Link
              onClick={() => setDashboardSideBarOpen(false)}
              to="laundries"
              className={`flex items-center gap-2 w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]
                    ${
                      isActive("/dashboard/laundries") ||
                      isActive(`/dashboard/laundries/${id}`)
                        ? "bg-HoverPrimaryColor"
                        : ""
                    }`}
            >
              <MdDashboard />
              <h1
                className={`${
                  !mob ? (sideBarOpen ? "block" : "hidden") : null
                }`}
              >
                {t("Laundries")}
              </h1>
            </Link>
          )}
          {isSuper && (
            <Link
              onClick={() => setDashboardSideBarOpen(false)}
              to="admins"
              className={`flex items-center gap-2 w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]
                    ${
                      isActive("/dashboard/admins") ||
                      isActive("/dashboard/addNewAdmin")
                        ? "bg-HoverPrimaryColor"
                        : ""
                    }`}
            >
              <MdDashboard />
              <h1
                className={`${
                  !mob ? (sideBarOpen ? "block" : "hidden") : null
                }`}
              >
                {t("Admins")}
              </h1>
            </Link>
          )}
          <Link
            onClick={() => setDashboardSideBarOpen(false)}
            to="employeesAndCars"
            className={` flex items-center gap-2 w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]
                    ${
                      isActive("/dashboard/employeesAndCars")
                        ? "bg-HoverPrimaryColor"
                        : ""
                    }`}
          >
            <FaUsers />
            <h1
              className={`${!mob ? (sideBarOpen ? "block" : "hidden") : null}`}
            >
              {t("Employees And Cars")}
            </h1>
          </Link>
          <Link
            onClick={() => setDashboardSideBarOpen(false)}
            to="orders"
            className={`flex items-center gap-2 w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]
                    ${
                      isActive("/dashboard/orders") ||
                      isActive(`/dashboard/orders/${id}`)
                        ? "bg-HoverPrimaryColor"
                        : ""
                    }`}
          >
            <TbWashMachine />
            <h1
              className={`${!mob ? (sideBarOpen ? "block" : "hidden") : null}`}
            >
              {t("Orders")}
            </h1>
          </Link>
          {/* <Link
            onClick={() => setDashboardSideBarOpen(false)}
            // to="transactions"
            className={`flex items-center gap-2 w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]
                    ${
                      isActive("/dashboard/transactions")
                        ? "bg-HoverPrimaryColor"
                        : ""
                    }`}
          >
            <GrTransaction />
            <h1
              className={`${!mob ? (sideBarOpen ? "block" : "hidden") : null}`}
            >
              {t("Transactions")}
            </h1>
          </Link> */}
          <Link
            onClick={() => setDashboardSideBarOpen(false)}
            to="products"
            className={`flex items-center gap-2 w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]
                    ${
                      isActive("/dashboard/products") ||
                      isActive(`/dashboard/products/${id}`)
                        ? "bg-HoverPrimaryColor"
                        : ""
                    }`}
          >
            <FaDollarSign />
            <h1
              className={`${!mob ? (sideBarOpen ? "block" : "hidden") : null}`}
            >
              {t("Products")}
            </h1>
          </Link>
          {/* <Link
            onClick={() => setDashboardSideBarOpen(false)}
            // to="reports"
            className={`flex items-center gap-2 w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]
                    ${
                      isActive("/dashboard/reports")
                        ? "bg-HoverPrimaryColor"
                        : ""
                    }`}
          >
            <IoNewspaperSharp />
            <h1
              className={`${!mob ? (sideBarOpen ? "block" : "hidden") : null}`}
            >
              {t("Reports")}
            </h1>
          </Link> */}
          <div
            onClick={() => {
              setLanguageOpen(true);
            }}
            className={`flex items-center gap-2 w-full h-full cursor-pointer p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]`}
          >
            <GrLanguage />
            <h1
              className={`${!mob ? (sideBarOpen ? "block" : "hidden") : null}`}
            >
              {t("Language")}
            </h1>
          </div>
          <div
            onClick={() => {
              setMakeDecisionOpen(true);
              setAction("logout");
            }}
            className={`flex items-center gap-2 w-full h-full cursor-pointer p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]`}
          >
            <FaPowerOff />
            <h1
              className={`${!mob ? (sideBarOpen ? "block" : "hidden") : null}`}
            >
              {t("Logout")}
            </h1>
          </div>
          {!mob && (
            <div>
              {lng === "English" ? (
                sideBarOpen ? (
                  <div
                    onClick={() => setSideBarOpen(false)}
                    className="flex items-center gap-2 cursor-pointer group w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]"
                  >
                    <AiOutlineClose />
                    <h1
                      className={`${
                        !mob ? (sideBarOpen ? "block" : "hidden") : null
                      }`}
                    >
                      {t("Hide")}
                    </h1>
                  </div>
                ) : (
                  <div
                    onClick={() => setSideBarOpen(true)}
                    className="flex items-center gap-2 cursor-pointer group w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]"
                  >
                    <MdOutlineArrowForwardIos className="group-hover:translate-x-1 duration-300" />
                  </div>
                )
              ) : sideBarOpen ? (
                <div
                  onClick={() => setSideBarOpen(false)}
                  className="flex items-center gap-2 cursor-pointer group w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]"
                >
                  <AiOutlineClose />
                  <h1
                    className={`${
                      !mob ? (sideBarOpen ? "block" : "hidden") : null
                    }`}
                  >
                    {t("Hide")}
                  </h1>
                </div>
              ) : (
                <div
                  onClick={() => setSideBarOpen(true)}
                  className="flex items-center gap-2 cursor-pointer group w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]"
                >
                  <MdOutlineArrowBackIos className="group-hover:-translate-x-1 duration-300" />
                </div>
              )}
            </div>
          )}
        </nav>
      </div>
    </div>
  );
}

export default DashboardSidebar;


import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  English: {
    translation: {
      // SelectLanguage
      "Choose Language": "Choose Language",
      English: "English",
      Arabic: "Arabic",
      // Admin
      "Welcome": "Welcome",
      "Please login": "Please login",
      "Email": "Email",
      "Password":"Password",
      "Your Email": "Your Email",
      "Your Password": "Your Password",
      "Login": "Login",
      // MainDashPage 
      "Orders for today": "Orders for today",
      "Total orders": "Total orders",
      "Pending orders": "Pending orders",
      "Cars in service": "Cars in service",
      "Income this month": "Income this month",
      "Orders Chart": "Orders Chart",
      "Daily": "Daily",
      "Monthly": "Monthly",
      "Yearly": "Yearly",
      "Start Date": "Start Date",
      "End Date": "End Date",
      "Update": "Update",

      // EmployeesAndCars
      "Car Id": "Car Id",
      "Driver Name": "Driver Name",
      "Details": "Details",
      "More": "More",
      "No data available": "No data available",
      // DriverById
      "Driver id": "Driver id",
      "Driver name": "Driver name",
      "Driver email": "Driver email",
      "Driver phone": "Driver phone",
      "Car location":"Car location",
      // Orders
      "Orders All Time": "Orders All Time",
      "Orders For Today": "Orders For Today",
      "All Orders": "All Orders",
      "Indirect": "Indirect",
      "Direct": "Direct",
      "Id": "Id",
      "Status": "Status",
      "Base Cost": "Base Cost",
      "Total Price": "Total Price",
      "Note": "Note",
      "Order Date": "Order Date",
      "Pickup Time": "Pickup Time",
      "Delivery Time": "Delivery Time",
      "Less": "Less",
      "More": "More",
      "No orders available": "No orders available",
      // OrdersStatsChart
      "Orders":"Orders",
      // UpdateDeliveryTime
      "Edit Order": "Edit Order",
      "Choose Date":"Choose Date",
      "Choose Time": "Choose Time",
      "Select Car": "Select Car",
      "Select Status": "Select Status",
      "Pending": "Pending",
      "Confirmed": "Confirmed",
      "Request":"Request",
      "Cancelled": "Cancelled",
      "Update": "Update",
                  // ProductItem
      "Edit":"Edit",
      // UpdateProductPrice
      "Update product price": "Update product price",
      "Product Price": "Product Price",
      "Update":"Update",
      // DashboardSidebar
"Dobe Pro": "Dobe Pro",
  "Dashboard": "Dashboard",
  "Employees And Cars": "Employees And Cars",
  "Orders": "Orders",
  "Transactions": "Transactions",
  "Prices": "Prices",
  "Reports": "Reports",
  "Language": "Language",
      "Logout": "Logout",
      "Hide":"Hide",
      // MakeDecision
      "Are you sure you want to": "Are you sure you want to",
      "logout":"logout",
      "this": "this",
      "Cancel": "Cancel",
      "Ok":"Ok",
    }
  },
  Arabic: {
    translation: {
      // SelectLanguage
      "Choose Language": "اختر اللغة",
      English: "الإنكليزية",
      Arabic: "العربية",
      // Admin
            "Welcome": "أهلا بكم",
      "Please login": "الرجاء تسجيل الدخول",
      "Email": "البريد الإلكتروني",
      "Password":"كلمة المرور",
      "Your Email": "البريد الإلكتروني",
      "Your Password": "كلمة المرور",
      "Login": "تسجيل دخول",
      // MainDashPage 
      "Orders for today": "الطلبات لهذا اليوم",
      "Total orders": "كل الطلبات",
      "Pending orders": "الطلبات المعلقة",
      "Cars in service": "سيارات في الخدمة",
      "Income this month": "الدخل هذا الشهر",
      "Orders Chart": "مخطط الطلبات",
      "Daily": "يومي",
      "Monthly": "شهري",
      "Yearly": "سنوي",
      "Start Date": "تاريخ البدء",
      "End Date": "تاريخ الانتهاء",
      "Update": "تحديث",
      // EmployeesAndCars
      "Car Id": "معرف السيارة",
      "Driver Name": "اسم الساثق",
      "Details": "التفاصيل",
      "More": "المزيد",
      "No data available": "لا يوجد بيانات متاحة",
      // DriverById
      "Driver id": "معرف السائق",
      "Driver name": "اسم الساثق",
      "Driver email": "ايميل السائق",
      "Driver phone": "رقم السائق",
      "Car location":"موقع السيارة",
      // Orders
      "Orders All Time": "كل الأوقات",
      "Orders For Today": "اليوم",
      "All Orders": "كل الطلبات",
      "Indirect": "الغير مباشرة",
      "Direct": "المباشرة",
      "Id": "المعرف",
      "Status": "الحالة",
      "Base Cost": "التكلفة الأساسية",
      "Total Price": "السعر الكلي",
      "Note": "ملاحظة",
      "Order Date": "تاريخ الطلب",
      "Pickup Time": "وقت الاستلام",
      "Delivery Time": "وقت التسليم",
      "Less": "أقل",
      "More": "المزيد",
      "No orders available": "لا يوجد بيانات متاحة",
      // OrdersStatsChart
      "Orders":"الطلبات",
      // UpdateDeliveryTime
      "Edit Order": "تعديل الطلب",
      "Choose Date":"اختر التاريخ",
      "Choose Time": "اختر الوقت",
      "Select Car": "اختر السيارة",
      "Select Status": "اختر الحالة",
      "Pending": "معلق",
      "Confirmed": "مؤكد",
      "Request":"غير مؤكد",
      "Cancelled": "ملغي",
      "Update": "تحديث",
      // ProductItem
      "Edit":"تعديل",
      // UpdateProductPrice
      "Update product price": "تعديل السعر",
      "Product Price": "سعر المنتج",
"Update": "تحديث",     
 // DashboardSidebar
"Dobe Pro": "دوبي برو",
  "Dashboard": "الرئيسية",
  "Employees And Cars": "السائقين و السيارات",
  "Orders": "الطلبات",
  "Transactions": "Transactions",
  "Prices": "الأسعار",
  "Reports": "التقارير",
  "Language": "اللغة",
      "Logout": "تسجيل خروج",
      "Hide":"غلق النافذة",
      // MakeDecision
      "Are you sure you want to": "هل انت متأكد أنك تريد",
      "logout": "تسجيل الخروج",
      "this": "هذا",
      "Cancel": "إلغاء",
      "Ok":"تأكيد",
    }
  },
};

const lng = localStorage.getItem("dpLanguage") || "English";
i18n.use(initReactI18next).init({
  resources,
  lng: lng,
  fallbackLng: lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import { toastError } from "../Components/ToastNotification/Toast";
import { apiAdmin, isSuper } from "./api";

export const laundryByAdmin = async (page_number = 1) => {
  try {
    const response = isSuper ? await apiAdmin.get(`/api/LaundryByAdmin?page=${page_number}`) : await apiAdmin.get(`/api/LaundryByAdmin`);
    return response.data;
  } catch (error) {
    console.log("laundry by admin err : ", error);
  }
};

export const updateProductPriceInLaundry = async (laundry_id,product_id,data) => {
  try {
    const response = await apiAdmin.post(`/api/update-Laundryprice?id=${product_id}&laundry_id=${laundry_id}`,data);
    return response.data;
  } catch (error) {
      console.log("update product price err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const fetchLaundries = async () => {
  try {
    const response = await apiAdmin.get(`/api/launderies`);
    return response.data;
  } catch (error) {
    console.log("fetch laundries err : ", error);
  }
};

export const fetchCarsByLaundry = async (laundry_id) => {
  try {
    const response = await apiAdmin.get(`/api/CarByLaundry?laundry_id=${laundry_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch cars by laundry err : ", error);
  }
};

// super admin

export const postLaundry = async (data) => {
  try {
    // name_en
    // name_ar
    // description_ar
    // description_en
    // phone_number
    // city 
    // address_line_1
    // address
    // point 
    // admin_id 
    // lat 
    // lng 
    // array_url[0][url_image]
    // array_url[1][url_image]
    // array_url[2][url_image]
    // array_ids[0][laundry_item_id]
    // array_ids[0][price]
    // array_ids[1][laundry_item_id]
    // array_ids[1][price]
    const response = await apiAdmin.post(`/api/Laundry`,data);
    return response.data;
  } catch (error) {
      console.log("post laundry err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const updateLaundry = async (laundry_id,data) => {
  try {
    // name_en
    // name_ar
    // description_ar
    // description_en
    // phone_number
    // city 
    // address_line_1
    // point 

    const response = await apiAdmin.post(`/api/Laundry-update?id=${laundry_id}`,data);
    return response.data;
  } catch (error) {
      console.log("update laundry err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const updateLaundryStatus = async (laundry_id) => {
  try {
    const response = await apiAdmin.post(`/api/UpdateStatusLaundery?id=${laundry_id}`);
    return response.data;
  } catch (error) {
      console.log("update laundry status err : ", error);
      toastError(error.response.data.data[0])
  }
};

export const fetchLaundryById = async (laundry_id) => {
  try {
    const response = await apiAdmin.get(`/api/laundry?id=${laundry_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch laundry by id err : ", error);
  }
};


export const fetchUsers = async (status_id) => {
  try {
    const response = await apiAdmin.get(`/api/Filter-users?status_id=${status_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch users by status id err : ", error);
  }
};


import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OrdersStatsChart = ({ ordersStats }) => {
  const { t } = useTranslation();
  const chartData = {
    labels: ordersStats.map((stat) => stat.label1),
    datasets: [
      {
        label: t("Orders"),
        data: ordersStats.map((stat) => stat.label2),
        backgroundColor: ordersStats.map((stat) =>
          stat.label2 > 4
            ? "#0070ad"
            : stat.label2 >= 2 && stat.label2 <= 4
            ? "orange"
            : "red"
        ),
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="chart-container w-full">
      <Bar data={chartData} options={options} className="chart" />
    </div>
  );
};

export default OrdersStatsChart;

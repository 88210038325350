import React, { useEffect, useState } from "react";
import { fetchAllOrders, fetchOrdersForToday } from "../Api/Orders";
import { useDashboard } from "../Context/DashboardContext";
import Skeleton from "react-loading-skeleton";
import { FaRegEdit } from "react-icons/fa";
import { usePopup } from "../Context/PopupContext";
import { useTranslation } from "react-i18next";
import Pagination from "../Components/Pagination";
import { Link } from "react-router-dom";

function Orders() {
  const { t } = useTranslation();
  const { orderStatusId, setOrderStatusId, today, setToday, updateOrders } =
    useDashboard();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [moreOpen, setMoreOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();

  // Function to toggle the open state for a specific order
  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getOrdersForToday = async (orderStatusId, currentPage) => {
    try {
      setLoading(true);
      const response = await fetchOrdersForToday(orderStatusId, currentPage);
      console.log(response.data);
      setOrders(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
    } catch (err) {
      console.error("get orders for today err : ", err);
    } finally {
      setLoading(false);
    }
  };
  const getAllOrders = async (orderStatusId, currentPage) => {
    try {
      setLoading(true);
      const response = await fetchAllOrders(orderStatusId, currentPage);
      console.log(response.data);
      setOrders(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
    } catch (err) {
      console.error("get all orders err : ", err);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (today === "1") {
      getOrdersForToday(orderStatusId, currentPage);
    } else {
      getAllOrders(orderStatusId, currentPage);
    }
  }, [today, orderStatusId, updateOrders, currentPage]);

  return (
    <div className="flex flex-col gap-3">
      <select
        className="bg-white outline-none focus:ring-0 cursor-pointer max-md:w-full md:min-w-[270px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[10px] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300"
        value={today}
        onChange={(e) => {
          setToday(e.target.value);
          setCurrentPage(1);
        }}
      >
        <option className="text-MainText" value="0">
          {t("Orders All Time")}
        </option>
        <option className="text-MainText" value="1">
          {t("Orders For Today")}
        </option>
      </select>
      <select
        className="bg-white outline-none focus:ring-0 cursor-pointer max-md:w-full md:min-w-[270px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[10px] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300"
        value={orderStatusId}
        onChange={(e) => {
          setOrderStatusId(e.target.value);
          setCurrentPage(1);
        }}
      >
        <option className="text-MainText" value="1">
          {t("All Orders")}
        </option>
        <option className="text-MainText" value="2">
          {t("Indirect")}
        </option>
        <option className="text-MainText" value="3">
          {t("Direct")}
        </option>
      </select>
      <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[10px]">
        <table
          className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
        >
          <thead>
            <tr>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px]">
                {t("Id")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] ">
                {t("Status")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Base Cost")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Total Price")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] md:hidden w-[70px]"></th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Note")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Order Date")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Pickup Time")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Delivery Time")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden w-[50px]">
                ...
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 10 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: 9 }).map((_, inIndex) => (
                    <td
                      key={inIndex}
                      className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                    >
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : orders.length > 0 ? (
              orders.map((order) => (
                <>
                  <tr key={order.id} className="">
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] w-[70px]">
                      {order.id}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                      {order.status}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      {parseFloat(order.base_cost).toFixed(2)} $
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      {parseFloat(order.total_price).toFixed(2)} $
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] md:hidden w-[70px]">
                      <span
                        className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                        onClick={() => toggleMoreOpen(order.id)}
                      >
                        {moreOpen[order.id] ? t("Less") : t("More")}
                      </span>
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      {order.note}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      {order.order_date}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      {order.pickup_time}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      {order.delivery_time}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden w-[50px]">
                      {order.order_type.type === "directly" && (
                        <Link to={`${order.id}`}>
                          <FaRegEdit
                            size={15}
                            className={`${
                              order.order_type.type === "directly"
                                ? "block"
                                : "hidden"
                            } mx-auto cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                          />
                        </Link>
                      )}
                    </td>
                  </tr>

                  <div
                    className={`${
                      moreOpen[order.id] ? "block" : "hidden"
                    } w-screen -mx-2 md:hidden`}
                  >
                    <table className="w-full">
                      <tbody>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Id")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {order.id}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Status")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {order.status}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Base Cost")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {parseFloat(order.base_cost).toFixed(2)} $
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Total Price")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {parseFloat(order.total_price).toFixed(2)} $
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Note")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {order.note}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Order Date")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {order.order_date}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Pickup Time")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {order.pickup_time}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Delivery Time")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {order.delivery_time}
                          </td>
                        </tr>
                        {order.order_type.type === "directly" && (
                          <tr>
                            <td className="font-bold text-center border border-[#9ea5ad] p-[12px] text-[13px]">
                              ...
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              <Link to={`${order.id}`}>
                                <FaRegEdit className="mx-auto cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300" />
                              </Link>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No orders available")}
              </div>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        itemPerPage={itemPerPage}
        handlePageClick={handlePageClick}
      />
    </div>
  );
}

export default Orders;

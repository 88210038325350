import React from "react";
import ProductItem from "../Components/ProductItem";
import { useDashboard } from "../Context/DashboardContext";
import ProductItemSkeleton from "../Components/SkeletonLoading/ProductItemSkeleton";
import { useTranslation } from "react-i18next";

function Products() {
  const lng = localStorage.getItem("dpLanguage") || "English";
  const { t } = useTranslation();
  const { loading, products } = useDashboard();

  return (
    <div className="flex flex-col gap-3 h-screen">
      {loading ? (
        Array.from({ length: 3 }).map((_, index) => (
          <div key={index}>
            <ProductItemSkeleton />
          </div>
        ))
      ) : products.length > 0 ? (
        products.map((product) => (
          <div key={product.id}>
            <ProductItem
              id={product.id}
              name={
                lng === "Arabic" ? product.item_type_ar : product.item_type_en
              }
              price={product.pivot.price}
            />
          </div>
        ))
      ) : (
        <div className="whitespace-nowrap px-4 py-2 max-md:px-2 max-md:py-1">
          {t("No data available")}
        </div>
      )}
    </div>
  );
}

export default Products;

import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useDashboard } from "../Context/DashboardContext";
import { useTranslation } from "react-i18next";
import { laundryByAdmin, updateLaundryStatus } from "../Api/Laundry";
import { toastSuccess } from "../Components/ToastNotification/Toast";
import { Link } from "react-router-dom";
import Pagination from "../Components/Pagination";
function Laundries() {
  const lng = localStorage.getItem("dpLanguage") || "English";
  const { t } = useTranslation();
  const [moreOpen, setMoreOpen] = useState({});
  const [laundries, setLaundries] = useState([]);
  const [laundriesLoading, setLaundriesLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const { dir, updateLaundriesData, setUpdateLaudriesData } = useDashboard();

  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };

  const getLaundriesData = async () => {
    try {
      setLaundriesLoading(true);
      const response = await laundryByAdmin(currentPage);
      console.log(response.data);
      setLaundries(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
    } catch (err) {
      console.error("get laundry data err : ", err);
    } finally {
      setLaundriesLoading(false);
    }
  };

  const handleUpdateStatus = async (id) => {
    try {
      const response = await updateLaundryStatus(id);
      console.log(response);
      if (response.success === true) {
        setUpdateLaudriesData((prev) => prev + 1);
        toastSuccess(response.message);
      }
    } catch (err) {
      console.error("update laundry status err : ", err);
    }
  };

  useEffect(() => {
    getLaundriesData();
  }, [dir, updateLaundriesData, currentPage]);

  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <Link
        to={`addNewLaundry`}
        className="bg-white outline-none focus:ring-0 w-fit min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[10px] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300"
      >
        Add new laundry
      </Link>
      <div className="flex flex-col gap-3">
        <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[10px]">
          <table
            className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
          >
            <thead>
              <tr>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px]">
                  {t("Id")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] ">
                  {t("Name")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Description")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Phone Number")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] md:hidden w-[70px]"></th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Address")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Admin id")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Status")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Img")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden w-[50px]">
                  ...
                </th>
              </tr>
            </thead>
            <tbody>
              {laundriesLoading ? (
                Array.from({ length: 10 }).map((_, outIndex) => (
                  <tr key={outIndex}>
                    {Array.from({ length: 9 }).map((_, inIndex) => (
                      <td
                        key={inIndex}
                        className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                      >
                        <Skeleton
                          width={"100%"}
                          className="h-[55px] max-md:h-[25px]"
                        />
                      </td>
                    ))}
                  </tr>
                ))
              ) : laundries.length > 0 ? (
                laundries.map((laundry) => (
                  <>
                    <tr key={laundry.id} className="">
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] w-[70px]">
                        {laundry.id}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                        {lng === "Arabic" ? laundry.name_ar : laundry.name_en}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {lng === "Arabic"
                          ? laundry.description_ar
                          : laundry.description_en}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {laundry.phone_number}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] md:hidden w-[70px]">
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(laundry.id)}
                        >
                          {moreOpen[laundry.id] ? t("Less") : t("More")}
                        </span>
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {laundry.address_line_1}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {laundry.admin_id}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        <select
                          onChange={() => handleUpdateStatus(laundry.id)}
                          value={
                            laundry.isActive === 1 ? "Active" : "Not active"
                          }
                          className="py-[7.5px] px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
                        >
                          <option value="Active">{t("Active")}</option>
                          <option value="Not active">{t("Not active")}</option>
                        </select>
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        <img
                          className="w-[200px] h-[150px]"
                          src={laundry?.laundry_media[0]?.url_image}
                          alt=""
                        />
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden w-[50px]">
                        <Link to={`${laundry.id}`}>
                          <FaRegEdit
                            size={15}
                            className={`mx-auto cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor mb-2 duration-300`}
                          />
                        </Link>
                        {/* <AiFillDelete
                          size={20}
                          className={`mx-auto cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300`}
                        /> */}
                      </td>
                    </tr>

                    <div
                      className={`${
                        moreOpen[laundry.id] ? "block" : "hidden"
                      } w-screen -mx-2 md:hidden`}
                    >
                      <table className="w-full">
                        <tbody>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Id")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {laundry.id}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Name")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {lng === "Arabic"
                                ? laundry.name_ar
                                : laundry.name_en}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Description")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {lng === "Arabic"
                                ? laundry.description_ar
                                : laundry.description_en}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Phone Number")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {laundry.phone_number}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Address")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {laundry.address_line_1}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Admin id")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {laundry.admin_id}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Status")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              <select
                                onChange={() => handleUpdateStatus(laundry.id)}
                                value={
                                  laundry.isActive === 1
                                    ? "Active"
                                    : "Not active"
                                }
                                className="py-[7.5px] px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
                              >
                                <option value="Active">{t("Active")}</option>
                                <option value="Not active">
                                  {t("Not active")}
                                </option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Img")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              <img
                                className="w-[200px] h-[150px] mx-auto"
                                src={laundry?.laundry_media[0]?.url_image}
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold text-center border border-[#9ea5ad] p-[12px] text-[13px]">
                              ...
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              <div className="mx-auto flex items-center gap-2 w-fit">
                                <Link to={`${laundry.id}`}>
                                  <FaRegEdit
                                    size={15}
                                    className=" cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                                  />
                                </Link>
                                {/* <AiFillDelete
                                  size={20}
                                  className=" cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300"
                                /> */}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                ))
              ) : (
                <div className="whitespace-nowrap p-[12px] text-[13px]">
                  {t("No laundries available")}
                </div>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
        />
      </div>
    </div>
  );
}

export default Laundries;

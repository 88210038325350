import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { fetchUsers } from "../Api/Laundry";

function Admins() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [moreOpen, setMoreOpen] = useState({});

  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };
  const getAdminsData = async () => {
    try {
      setLoading(true);
      const response = await fetchUsers(1);
      console.log(response);
      setAdmins(response.data);
    } catch (err) {
      console.error("get admins data err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdminsData();
  }, []);
  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <Link
        to={`addNewAdmin`}
        className="bg-white outline-none focus:ring-0 w-fit min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[10px] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300"
      >
        {t("Add new admin")}
      </Link>
      <div className="flex flex-col gap-3">
        <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[10px]">
          <table
            className={`bg-white text-MainText w-full table-fixed max-w-[screen] overflow-x-auto`}
          >
            <thead>
              <tr>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px]">
                  {t("Id")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] ">
                  {t("Name")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Email")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px] md:hidden"></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                Array.from({ length: 10 }).map((_, outIndex) => (
                  <tr key={outIndex}>
                    {Array.from({ length: 3 }).map((_, inIndex) => (
                      <td key={inIndex}>
                        <Skeleton
                          width={"100%"}
                          className="h-[55px] max-md:h-[25px]"
                        />
                      </td>
                    ))}
                  </tr>
                ))
              ) : admins.length > 0 ? (
                admins.map((admin) => (
                  <>
                    {" "}
                    <tr key={admin.id} className="">
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] w-[70px]">
                        {admin.id}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                        {admin.name}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {admin.email}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] md:hidden w-[70px]">
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(admin.id)}
                        >
                          {moreOpen[admin.id] ? t("Less") : t("More")}
                        </span>
                      </td>
                    </tr>
                    <div
                      className={`${
                        moreOpen[admin.id] ? "block" : "hidden"
                      } w-screen -mx-2 md:hidden`}
                    >
                      <table className="w-full">
                        <tbody>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Id")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {admin.id}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Name")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {admin.name}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Email")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {admin.email}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                ))
              ) : (
                <div className="whitespace-nowrap p-[12px] text-[13px]">
                  {t("No admins available")}
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Admins;

import React, { useEffect, useRef, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { fetchCarsByLaundry } from "../Api/Laundry";
import { useDashboard } from "../Context/DashboardContext";
import { fetchOrderById, updateDeliveryDate } from "../Api/Orders";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function UpdateOrder() {
  const { id } = useParams();
  const { t } = useTranslation();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const laundryId = localStorage.getItem("laundryId");
  const { handleInputsClick } = useDashboard();
  const [deliveryDate, setDeliveryDate] = useState();
  const [deliveryTime, setDeliveryTime] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [order, setOrder] = useState({});
  const [orderDataLoading, setOrderDataLoading] = useState(true);
  const [carId, setCarId] = useState(1);
  const [loading, setLoading] = useState(false);
  const [cars, setCars] = useState([]);
  const [carsLoading, setCarsLoading] = useState(true);
  const [status, setStatus] = useState("");
  const dateInput = useRef(null);
  const timeInput = useRef(null);

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
    return `${adjustedHours}:${
      minutes < 10 ? `0${minutes}` : minutes
    } ${period}`;
  };

  const getCarsByLaundry = async () => {
    try {
      setCarsLoading(true);
      const response = await fetchCarsByLaundry(laundryId);
      console.log(response.data);
      setCars(response.data);
    } catch (err) {
      console.error("get cars by laundry err : ", err);
    } finally {
      setCarsLoading(false);
    }
  };
  const getOrderData = async () => {
    try {
      setOrderDataLoading(true);
      const response = await fetchOrderById(id);
      console.log(response);
      setOrder(response.data);
      const dateTimeParts = response.data.delivery_time.split(" ");
      const [date, time] = dateTimeParts;
      setDeliveryDate(date);
      setDeliveryTime(time);
      setOrderStatus(response.data.status);
    } catch (err) {
      console.error("get order by id data err : ", err);
    } finally {
      setOrderDataLoading(false);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("delivery_date", `${deliveryDate} ${deliveryTime}`);
    formData.append("car_id", carId);
    formData.append("status", status);
    try {
      setLoading(true);
      const response = await updateDeliveryDate(id, formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/orders";
      }
    } catch (err) {
      console.error("update delivery time err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderData();
    getCarsByLaundry();
  }, []);

  return (
    <form className="flex flex-col gap-5 relative" onSubmit={submitHandler}>
      <div className="font-[500]">{t("Edit Order")}</div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="date"
          >
            {t("Choose Date")} <span className="text-red-600">*</span> :
          </label>
          {orderDataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <div
              onClick={() => handleInputsClick(dateInput)}
              className="bg-white relative py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer"
            >
              {deliveryDate ? deliveryDate : t("Choose Date")}
              <input
                id="date"
                ref={dateInput}
                value={deliveryDate}
                onChange={(e) => setDeliveryDate(e.target.value)}
                type="date"
                className="absolute top-0 left-0 invisible px-4 py-2 text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer"
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="time"
          >
            {t("Choose Time")} <span className="text-red-600">*</span> :
          </label>
          {orderDataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <div
              onClick={() => handleInputsClick(timeInput)}
              className="bg-white relative py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer"
            >
              {deliveryTime ? formatTime(deliveryTime) : t("Choose Time")}
              <input
                id="time"
                ref={timeInput}
                value={deliveryTime}
                onChange={(e) => setDeliveryTime(e.target.value)}
                type="time"
                className="absolute top-0 left-0 invisible px-4 py-2 text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer"
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="car"
          >
            {t("Select Car")} <span className="text-red-600">*</span> :
          </label>
          {carsLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <select
              id="car"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
              value={carId}
              onChange={(e) => setCarId(e.target.value)}
            >
              <option disabled value="">
                {t("Select Car")}
              </option>
              {cars.map((car) => (
                <option
                  className="text-MainText bg-white"
                  key={car.id}
                  value={car.id}
                >
                  {car.driver.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="status"
          >
            {t("Select Status")} <span className="text-red-600">*</span> :
          </label>
          {orderDataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[10px] cursor-pointer min-h-[38.34px]" />
          ) : (
            <select
              id="status"
              className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 rounded-[10px] cursor-pointer min-h-[38.34px]"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option disabled value="">
                {t("Select Status")}
              </option>

              <option
                value={"pending"}
                className={`${"pending" === orderStatus && "hidden"}`}
              >
                {t("Pending")}
              </option>
              <option
                value={"confirmed"}
                className={`${"confirmed" === orderStatus && "hidden"}`}
              >
                {t("Confirmed")}
              </option>
              <option
                value={"request"}
                className={`${"request" === orderStatus && "hidden"}`}
              >
                {t("Request")}
              </option>
              <option
                value={"cancelled"}
                className={`${"cancelled" === orderStatus && "hidden"}`}
              >
                {t("Cancelled")}
              </option>
            </select>
          )}
        </div>
      </div>
      <button
        type="submit"
        onClick={submitHandler}
        className={`${
          loading ? "cursor-default pointer-events-none" : "cursor-pointer"
        } min-w-[81.38px] min-h-[38.34px] py-[7.5px] px-[12px] text-[13px] rounded-[10px] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
      >
        {loading ? (
          <AiOutlineLoading3Quarters className="animate-spin" />
        ) : (
          t("Update")
        )}
      </button>
    </form>
  );
}

export default UpdateOrder;

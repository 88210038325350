import { React, useState } from "react";
import { usePopup } from "../Context/PopupContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Popup from "./Popup";
import { logout } from "../Api/Auth";
import Cookies from "js-cookie";
import { toastSuccess } from "./ToastNotification/Toast";
import { useTranslation } from "react-i18next";
import { isSuper } from "../Api/api";

function MakeDecision() {
  const { t } = useTranslation();
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const {
    makeDecisionOpen,
    setMakeDecisionOpen,
    setDashboardSideBarOpen,
    type,
    action,
  } = usePopup();
  const handleConfirm = async () => {
    setSpinnerLoad(true);
    try {
      if (action === "logout") {
        const response = await logout();
        console.log(response);
        if (response.success === true) {
          isSuper
            ? Cookies.remove("dpSuperAdminToken")
            : Cookies.remove("dpAdminToken");
          window.location.pathname = "/";
          toastSuccess(response.message);
        }
      }
    } catch (error) {
      console.error(`${action} ${type} Error`, error);
    } finally {
      setSpinnerLoad(false);
      setMakeDecisionOpen(false);
      setDashboardSideBarOpen(false);
    }
  };
  return (
    <Popup
      toggleSwitch={makeDecisionOpen}
      children={
        <div
          className={`text-MainText bg-white max-md:min-w-full overflow-hidden flex flex-col gap-2`}
        >
          <h1 className="font-[500]">
            {t("Are you sure you want to")} {action === "logout" && t("logout")}{" "}
            {type ? `${t("this")} ${type}` : null} ?
          </h1>
          <div className="flex items-center justify-start mt-3 gap-3">
            <button
              onClick={() => setMakeDecisionOpen(false)}
              className="py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[10px] border border-[#9ea5ad] hover:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 flex justify-center items-center min-w-[64px] min-h-[38.34px]"
            >
              {t("Cancel")}
            </button>

            <button
              onClick={handleConfirm}
              className={`${
                spinnerLoad
                  ? "cursor-default pointer-events-none"
                  : "cursor-pointer"
              } py-[7.5px] px-[12px] text-[13px] rounded-[10px] border border-[#9ea5ad] hover:border-MainText hover:bg-gradient-to-b hover:from-[#78bcd0] hover:to-[#00238c] hover:text-white duration-300 flex justify-center items-center min-w-[64px] min-h-[38.34px]`}
            >
              {spinnerLoad ? (
                <AiOutlineLoading3Quarters className="animate-spin" />
              ) : (
                t("Ok")
              )}
            </button>
          </div>
        </div>
      }
      overLayClassName={`z-40`}
      className={`text-MainText bg-white top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] fixed z-50 overflow-hidden rounded-[10px] p-4`}
      onClose={() => setMakeDecisionOpen(false)}
    />
  );
}

export default MakeDecision;
